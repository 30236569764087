<template>
  <div class="bcclBox">
    <div class="head">
      <div class="line1">材料种类</div>
      <div class="line2">材料类型</div>
      <div class="line3">材料内容</div>
    </div>
    <div class="item">
      <div class="line1">店铺信息</div>
      <div class="line2">
        <div>店铺认证截图</div>
        <div>店铺货物截图</div>
        <div>支付流程截图</div>
        <div>店铺结算记录截图</div>
        <div>店铺链接</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="storeAuthPicSuccess"
          >
            <img
              v-if="formData.storeAuthPic"
              :src="formData.storeAuthPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.storeAuthPic"
              class="avatar-uploader-span"
              @click.stop="formData.storeAuthPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="storeComPicSuccess"
          >
            <img
              v-if="formData.storeComPic"
              :src="formData.storeComPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.storeComPic"
              class="avatar-uploader-span"
              @click.stop="formData.storeComPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="storePayPicSuccess"
          >
            <img
              v-if="formData.storePayPic"
              :src="formData.storePayPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.storePayPic"
              class="avatar-uploader-span"
              @click.stop="formData.storePayPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="storeSetPicSuccess"
          >
            <img
              v-if="formData.storeSetPic"
              :src="formData.storeSetPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.storeSetPic"
              class="avatar-uploader-span"
              @click.stop="formData.storeSetPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Input
            v-model="formData.storeUrl"
            class="inputs"
            placeholder="输入店铺链接"
          ></Input>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="formData.storeAuthPic != '' && formData.storeAuthPic != null"
            href="javascript:;"
            @click="showImgModal(formData.storeAuthPic)"
          >
            <img :src="formData.storeAuthPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.storeComPic != '' && formData.storeComPic != null"
            href="javascript:;"
            @click="showImgModal(formData.storeComPic)"
          >
            <img :src="formData.storeComPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.storePayPic != '' && formData.storePayPic != null"
            href="javascript:;"
            @click="showImgModal(formData.storePayPic)"
          >
            <img :src="formData.storePayPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.storeSetPic != '' && formData.storeSetPic != null"
            href="javascript:;"
            @click="showImgModal(formData.storeSetPic)"
          >
            <img :src="formData.storeSetPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.storeUrl != '' && formData.storeUrl != null"
            :href="formData.storeUrl"
            target="_black"
            style="color: var(--themeColor)"
          >
            {{ formData.storeUrl }}
          </a>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="line1">APP信息</div>
      <div class="line2">
        <div>支付流程截图</div>
        <div>首页货品截图</div>
        <div>认证页截图</div>
        <div>APP 名称</div>
        <div>APP 账号</div>
        <div>APP 密码</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="appPayPicSuccess"
          >
            <img
              v-if="formData.appPayPic"
              :src="formData.appPayPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.appPayPic"
              class="avatar-uploader-span"
              @click.stop="formData.appPayPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="appComPicSuccess"
          >
            <img
              v-if="formData.appComPic"
              :src="formData.appComPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.appComPic"
              class="avatar-uploader-span"
              @click.stop="formData.appComPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="appAuthPicSuccess"
          >
            <img
              v-if="formData.appAuthPic"
              :src="formData.appAuthPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.appAuthPic"
              class="avatar-uploader-span"
              @click.stop="formData.appAuthPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Input
            v-model="formData.appName"
            class="inputs"
            placeholder="输入APP名称"
          ></Input>
        </div>
        <div>
          <Input
            v-model="formData.appAccount"
            class="inputs"
            placeholder="输入APP账号"
          ></Input>
        </div>
        <div>
          <Input
            v-model="formData.appPasswordAPP"
            class="inputs"
            placeholder="输入APP密码"
          ></Input>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="formData.appPayPic != '' && formData.appPayPic != null"
            href="javascript:;"
            @click="showImgModal(formData.appPayPic)"
          >
            <img :src="formData.appPayPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.appComPic != '' && formData.appComPic != null"
            href="javascript:;"
            @click="showImgModal(formData.appComPic)"
          >
            <img :src="formData.appComPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.appAuthPic != '' && formData.appAuthPic != null"
            href="javascript:;"
            @click="showImgModal(formData.appAuthPic)"
          >
            <img :src="formData.appAuthPic" class="approveImg" />
          </a>
        </div>
        <div>
          <p>{{ formData.appName }}</p>
        </div>
        <div>
          <p>{{ formData.appAccount }}</p>
        </div>
        <div>
          <p>{{ formData.appPasswordAPP }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="line1">公众号信息</div>
      <div class="line2">
        <div>支付流程截图</div>
        <div>首页货品截图</div>
        <div>认证页截图</div>
        <div>公众号名称</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="mpPayPicSuccess"
          >
            <img
              v-if="formData.mpPayPic"
              :src="formData.mpPayPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.mpPayPic"
              class="avatar-uploader-span"
              @click.stop="formData.mpPayPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="mpComPicSuccess"
          >
            <img
              v-if="formData.mpComPic"
              :src="formData.mpComPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.mpComPic"
              class="avatar-uploader-span"
              @click.stop="formData.mpComPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="mpAuthPicSuccess"
          >
            <img
              v-if="formData.mpAuthPic"
              :src="formData.mpAuthPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.mpAuthPic"
              class="avatar-uploader-span"
              @click.stop="formData.mpAuthPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Input
            v-model="formData.mpName"
            class="inputs"
            placeholder="输入公众号名称"
          ></Input>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="formData.mpPayPic != '' && formData.mpPayPic != null"
            href="javascript:;"
            @click="showImgModal(formData.mpPayPic)"
          >
            <img :src="formData.mpPayPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.mpComPic != '' && formData.mpComPic != null"
            href="javascript:;"
            @click="showImgModal(formData.mpComPic)"
          >
            <img :src="formData.mpComPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.mpAuthPic != '' && formData.mpAuthPic != null"
            href="javascript:;"
            @click="showImgModal(formData.mpAuthPic)"
          >
            <img :src="formData.mpAuthPic" class="approveImg" />
          </a>
        </div>
        <div>
          <p>{{ formData.mpName }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="line1">H5 信息</div>
      <div class="line2">
        <div>支付流程截图</div>
        <div>首页货品截图</div>
        <div>H5 链接</div>
        <div>H5 账号</div>
        <div>H5 密码</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="h5PayPicSuccess"
          >
            <img
              v-if="formData.h5PayPic"
              :src="formData.h5PayPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.h5PayPic"
              class="avatar-uploader-span"
              @click.stop="formData.h5PayPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="h5ComPicSuccess"
          >
            <img
              v-if="formData.h5ComPic"
              :src="formData.h5ComPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.h5ComPic"
              class="avatar-uploader-span"
              @click.stop="formData.h5ComPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Input
            v-model="formData.h5Url"
            class="inputs"
            placeholder="输入H5链接"
          ></Input>
        </div>
        <div>
          <Input
            v-model="formData.h5Account"
            class="inputs"
            placeholder="输入H5账号"
          ></Input>
        </div>
        <div>
          <Input
            v-model="formData.h5Password"
            class="inputs"
            placeholder="输入H5密码"
          ></Input>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="formData.h5PayPic != '' && formData.h5PayPic != null"
            href="javascript:;"
            @click="showImgModal(formData.h5PayPic)"
          >
            <img :src="formData.h5PayPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.h5ComPic != '' && formData.h5ComPic != null"
            href="javascript:;"
            @click="showImgModal(formData.h5ComPic)"
          >
            <img :src="formData.h5ComPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.h5Url != '' && formData.h5Url != null"
            :href="formData.h5Url"
            target="_black"
            style="color: var(--themeColor)"
          >
            {{ formData.h5Url }}
          </a>
        </div>
        <div>
          <p>{{ formData.h5Account }}</p>
        </div>
        <div>
          <p>{{ formData.h5Password }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="line1">小程序信息</div>
      <div class="line2">
        <div>支付流程截图</div>
        <div>首页货品截图</div>
        <div>认证页截图</div>
        <div>小程序名称</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="miniPayPicSuccess"
          >
            <img
              v-if="formData.miniPayPic"
              :src="formData.miniPayPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.miniPayPic"
              class="avatar-uploader-span"
              @click.stop="formData.miniPayPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="miniComPicSuccess"
          >
            <img
              v-if="formData.miniComPic"
              :src="formData.miniComPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.miniComPic"
              class="avatar-uploader-span"
              @click.stop="formData.miniComPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUpload"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="miniAuthPicSuccess"
          >
            <img
              v-if="formData.miniAuthPic"
              :src="formData.miniAuthPic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.miniAuthPic"
              class="avatar-uploader-span"
              @click.stop="formData.miniAuthPic = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
        <div>
          <Input
            v-model="formData.miniName"
            class="inputs"
            placeholder="输入小程序名称"
          ></Input>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="formData.miniPayPic != '' && formData.miniPayPic != null"
            href="javascript:;"
            @click="showImgModal(formData.miniPayPic)"
          >
            <img :src="formData.miniPayPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.miniComPic != '' && formData.miniComPic != null"
            href="javascript:;"
            @click="showImgModal(formData.miniComPic)"
          >
            <img :src="formData.miniComPic" class="approveImg" />
          </a>
        </div>
        <div>
          <a
            v-if="formData.miniAuthPic != '' && formData.miniAuthPic != null"
            href="javascript:;"
            @click="showImgModal(formData.miniAuthPic)"
          >
            <img :src="formData.miniAuthPic" class="approveImg" />
          </a>
        </div>
        <div>
          <p>{{ formData.miniName }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="line1">代运营公司合作证明</div>
      <div class="line2">
        <div>代运营公司合作协议或合作说明</div>
      </div>
      <div v-if="isAdd || isEdit" class="line3">
        <div>
          <Upload
            class="avatar-uploader"
            :before-upload="beforeUploadPDF"
            drag
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="businessContractSuccess"
            style="width: 94%"
          >
            <!-- <img  v-if="formData.businessContract"  :src="formData.businessContract"  class="avatar" /> -->
            <div
              v-if="formData.businessContract"
              class="avatar"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0px 4px;
                box-sizing: border-box;
                text-align: left;
              "
            >
              {{ formData.businessContract }}
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              v-if="formData.businessContract"
              class="avatar-uploader-span"
              @click.stop="formData.businessContract = ''"
              ><i class="el-icon-close"></i
            ></span>
          </Upload>
        </div>
      </div>
      <div v-else class="line3">
        <div>
          <a
            v-if="
              formData.businessContract != '' && formData.businessContract != null
            "
            :href="formData.businessContract"
            target="_black"
            style="color: var(--themeColor)"
          >
            {{ formData.businessContract }}
          </a>
        </div>
      </div>
    </div>
    <!-- 查看图片 -->
    <BigImage :show-image.sync="showBigImg" :src="bigImgSrc" />
  </div>
</template>

<script>
import { URL_API } from "@/utils/apiUrl.js";
import * as imageConversion from "image-conversion";
import BigImage from '@/components/BigImage';
import md5 from "js-md5";
export default {
  components: { BigImage },
  props: {
    type: {
      type: String,
      default: 'detail'
    },
    formData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isImge: true,
      mchDetail: "",
      uploadUrl: URL_API.upload,
      md5,
      showBigImg: false,
      bigImgSrc: ''
    };
  },
  created () {
  },
  computed: {
    isAdd () {
      return this.type === 'add'
    },
    isEdit () {
      return this.type === 'edit'
    }
  },
  methods: {
    // 查看图片
    showImgModal (cover) {
      this.showBigImg = true
      this.bigImgSrc = cover
    },
    //代运营公司合作协议或合作说明
    businessContractSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.businessContract = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //小程序信息
    miniPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //H5 信息
    h5PayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5PayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    h5ComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5ComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //公众号信息
    mpPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //APP信息
    appPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 店铺信息
    storeAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    storeComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    storePayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storePayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    storeSetPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeSetPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    beforeUpload(file) {
      this.isImge = true;
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = ['jpg', 'jpeg', 'png', 'bpm'].includes(testmsg.toLocaleLowerCase());
      if (!extension) {
        this.$Message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return;
      }
      console.log(file.size, "压缩前");
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 1024).then((res) => {
          const fileOfBlob = new File([res], `${new Date().getTime()}.${testmsg}`)
          resolve(fileOfBlob)
        });
      });
    },
    beforeUploadPDF(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "pdf";
      if (!extension) {
        this.$Message.error("上传图片仅支持pdf格式");
        this.isImge = false;
        return;
      }
      console.log(file.name, "压缩前");
      return new Promise((resolve) => {
        resolve(file);
      });
    },
  }
}
</script>
<style scoped>
@import "../../../../../assets/css/desc.css";
.bcclBox {
  border: 1px solid #d7dae2;
  border-bottom: none;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  margin-top: 20px;
}
.bcclBox .head,
.bcclBox .item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d7dae2;
  text-align: center;
}
.bcclBox .head > div {
  height: 60px;
  line-height: 60px;
}
.bcclBox .head .line1,
.bcclBox .item .line1 {
  width: 200px;
}
.bcclBox .head .line2,
.bcclBox .item .line2 {
  width: 240px;
  border-right: 1px solid #d7dae2;
  border-left: 1px solid #d7dae2;
}
.bcclBox .head .line3,
.bcclBox .item .line3 {
  width: calc(100% - 440px);
}
.bcclBox .item .line2 > div,
.bcclBox .item .line3 > div {
  height: 80px;
  /* line-height: 80px; */
  border-bottom: 1px solid #d7dae2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bcclBox .item .line3 > div .inputs {
  width: 94%;
}

.bcclBox .item .line2 > div:last-child,
.bcclBox .item .line3 > div:last-child {
  border-bottom: none;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}
.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}
.upload_wj_left i {
  color: var(--themeColor);
  padding-right: 3px;
}
.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}

.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}
.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.upload_ph p span i {
  color: var(--themeColor);
  padding-right: 3px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: var(--themeColor);
  padding-right: 3px;
}

.avatar-uploader {
  width: 60px;
  height: 60px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgb(241, 242, 244);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.avatar-uploader .Upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.tab1 {
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .Input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
.approveImg{
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
</style>
